import axios from "axios";
import authService from "./authService";
import usersService from "./usersService";

axios.defaults.baseURL = "https://sms.kresidence.az:8443";

axios.interceptors.request.use((config) => {
  if (typeof window === "undefined") {
    return config;
  }

  const token = window.localStorage.getItem("accessToken");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.request.status === 401 && err.config.url !== "api/users/sign-in") {
      const res = await authService.resetLogin();
      const config = err.config;

      if (!res) {
        throw err;
      }

      const accessToken = res.data.accessToken;
      config.headers.Authorization = `Bearer ${accessToken}`;

      return new Promise((resolve, reject) => {
        axios
          .request(config)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } else {
      throw err;
    }
  }
);

const globalService = {
  login: authService.login,
  getUsers: usersService.getUsers,
};

export default globalService;
