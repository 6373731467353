import axios from "axios";

const usersService = {
  getUsers(params) {
    return new Promise((resolve, reject) => {
      axios
          .get("api/users/get-all", {
            params,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
    });
  },
  getUserById(id) {
    return new Promise((resolve, reject) => {
      axios
          .get(`api/users/get-by-id?id=${id}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
    });
  },
  createUser(form) {
    return new Promise((resolve, reject) => {
      axios
          .post("api/users/create", form)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
    });
  },
  updateUser(form) {
    return new Promise((resolve, reject) => {
      axios
          .put("api/users/update", form)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
    });
  },
  deleteUser(id) {
    return new Promise((resolve, reject) => {
      axios
          .delete(`api/users/delete?id=${id}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
    });
  },
  changePassword({id, password, repeatPassword}) {
    return new Promise((resolve, reject) => {
      axios
          .put("api/users/change-password", {id, password, repeatPassword})
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }
};

export default usersService;
