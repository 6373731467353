<template>
  <v-snackbar v-model="snackbar" :timeout="1500" :color="type" top right>
    <v-icon v-if="type === 'success'" size="20" class="mr-4"
      >mdi-check-circle</v-icon
    >
    <v-icon v-if="type === 'error'" size="20" class="mr-4">mdi-alert</v-icon>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    type: null,
    message: null
  }),
  created() {
    this.$eventBus.$on("notification", ({ message, type }) => {
      this.snackbar = true;
      this.message = message;
      this.type = type;
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("notification", null);
  }
};
</script>

<style lang="scss">
.v-snack {
  padding: 1rem !important;
}
</style>
