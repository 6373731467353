import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store/index";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isPublic = to.matched.some((record) => record.meta.isOnlyPublic);
  const isAuthenticated = store.getters["auth/isAuthenticated"];

  if (!isPublic && !isAuthenticated) {
    return next({
      path: "/login",
      query: {
        redirect: to.path,
      },
    });
  }

  if (isPublic && !isAuthenticated) {
    //
  }

  if (isAuthenticated && !requiresAuth) {
    return next("/dashboard");
  }

  next();
});

export default router;
