import store from "../store/index";
import router from "../router/index";
import axios from "axios";

const authService = {
  resetLogin() {
    store.dispatch("auth/setAccessToken", null);
    localStorage.clear();
    router.push({ name: "Login" });
  },

  login(credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/users/sign-in", credentials)
        .then((res) => {
          if (res.data.accessToken) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default authService;
