const Dashboard = () => import("../views/Dashboard/index.vue");
const Login = () => import("../views/Login/index.vue");
const Users = () => import("../views/Users/index.vue");

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      isOnlyPublic: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      isOnlyPublic: false,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
      isOnlyPublic: false,
    },
  },
];

export default routes;
