<template>
  <div class="home">
    <AppHeader v-if="isAuthenticated" />
    <v-main>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AppHeader from "./AppHeader.vue";

export default {
  name: "Home",
  components: { AppHeader },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
};
</script>
