const state = {
  users: [],
  totalCount: 0,
  searchForm: {
    offset: null,
    limit: null,
    firstName: null,
    lastName: null, 
    userName: null, 
    phoneNumber: null,
    email: null
  },
  user: {}
};

const getters = {
  searchItemsCount: state => state.searchItemsCount,
  searchingRequests: state => state.searchingRequests
};

const mutations = {
  SET_SEARCH_FORM(state, data) {
    state.searchForm = data;
  },
  SET_USERS(state, { totalCount, users }) {
    state.users = users;
    state.totalCount = totalCount;
  },
  SET_USER(state, user) {
    state.user = user;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
