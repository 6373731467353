<template>
  <v-app>
    <AppMain />
    <Snackbar />
  </v-app>
</template>

<script>
import AppMain from './components/layout/AppMain.vue';
import Snackbar from './components/common/Snackbar.vue';

export default {
  name: "App",
  components: { AppMain, Snackbar }
};
</script>
