import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import router from "./router";
import store from "./store";

export const eventBus = new Vue();
Vue.use(Vuelidate)

Vue.config.productionTip = false

Vue.prototype.$eventBus = eventBus;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
