<template>
  <v-app-bar app color="primary" dark>
    <v-app-bar-title>SMS Bulking</v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn
      v-if="checkRoots('/users')"
      class="ml-5"
      to="/users"
      color="orange"
      dark
    >
      İstifadəçilər
    </v-btn>
    <v-btn
      v-if="checkRoots('/dashboard')"
      class="ml-5"
      to="/dashboard"
      color="orange"
      dark
    >
      Müştərilər
    </v-btn>
    <v-btn class="ml-5" @click="logOut" color="orange" dark>
      Çıxış
    </v-btn>
  </v-app-bar>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapActions} = createNamespacedHelpers("auth");

export default {
  methods: {
    ...mapActions(["logout"]),
    logOut() {
      this.logout();
      this.$router.go();
    },
    checkRoots(to) {
      if (to.includes(this.$route.path)) {
        return false;
      }
      return true;
    },
  },
  computed: {
    currentRouteName() {
      console.log("this.$router ", this.$route.path);
      return this.$router.history.current.name;
    },
  },
};
</script>
