import globalService from "@/services/globalService";
import router from "@/router/index";

const accessToken = localStorage.getItem("accessToken");

const state = {
    accessToken,
  },
  getters = {
    accessToken(state) {
      return state.accessToken;
    },

    isAuthenticated(state) {
      return !!state.accessToken;
    },
  };

const actions = {
  setAccessToken({ commit }, payload) {
    commit("setAccessToken", payload);
  },

  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      globalService
        .login(payload)
        .then((res) => {
          commit("setAccessToken", res.accessToken);
          // commit("setUserInfo", res.userInfo);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  logout() {
    localStorage.clear();
    router.push("/login");
  },
};

const mutations = {
  setAccessToken(state, token) {
    state.accessToken = token;
    localStorage.setItem("accessToken", token);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
